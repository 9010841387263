import {
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Field, useFormikContext } from "formik";
import React from "react";
import { formatCNPJOrCPF } from "../../../../utils";
import IFormEditUser from "./IFormEditUser";
import { CheckListIcon } from "@components/Icons";
import { AddCircleOutlined, DeleteForeverOutlined } from "@material-ui/icons";
import useAddCNPJ from "@pages/Admin/hooks/useAddCNPJ";

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonAdd: {
      color: green[600],
    },
    titleDataUser: {
      fontFamily: "DM Sans",
      fontWeight: 700,
      fontSize: "20px",
      color: "#3E3D3D",
    },
    gridContainer: {
      backgroundColor: "#fff",
      padding: "3rem",
      margin: "0 0 10px 3rem",
      borderRadius: "4px",
      width: "95%",
    },
    button: {
      width: "15rem",
      textTransform: "capitalize",
      boxShadow: "none",
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1.5rem",
    },
    secondaryItem: {
      fontSize: "1.3rem",
      color: "#CCCCCC",
    },
    lastName: {
      marginLeft: ".7rem",

      "@media screen and (max-width: 768px)": {
        marginLeft: 0,
      },
    },
    tableCompany: {
      border: "1px solid #72655133",
      borderRadius: "4px",
    },
  })
);

interface Props {
  loading: boolean;
}

const FormEditUser: React.FC<Props> = ({ loading }) => {
  const classes = useStyles();
  const { touched, values, errors, setFieldValue } = useFormikContext<
    IFormEditUser
  >();

  const handleAddCNPJ = useAddCNPJ(values, setFieldValue);

  return (
    <Grid className={classes.gridContainer} container spacing={2}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
      >
        <Typography className={classes.titleDataUser}>
          Dados do usuário
        </Typography>
        <CheckListIcon style={{ height: "2rem" }} />
      </Grid>

      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid style={{ marginTop: "4rem" }} container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              name="firstName"
              as={TextField}
              variant="outlined"
              placeholder="Primeiro nome"
              fullWidth
              error={touched.firstName && !!errors.firstName}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Field
              className={classes.lastName}
              name="lastName"
              as={TextField}
              variant="outlined"
              placeholder="Sobrenome"
              fullWidth
              error={touched.lastName && !!errors.lastName}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
        </Grid>

        <Grid style={{ marginTop: "4rem" }} container>
          <Grid item xs={12} md={6} lg={8}>
            <Field
              name="email"
              as={TextField}
              variant="outlined"
              placeholder="E-mail"
              fullWidth
              error={touched.email && !!errors.email}
              helperText={touched.email && errors.email}
            />
          </Grid>
        </Grid>

        <Grid
          style={{ marginTop: "4rem", display: "flex", alignItems: "center" }}
          container
          spacing={2}
        >
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="CNPJ"
              variant="outlined"
              placeholder="CNPJ ou CPF"
              fullWidth
              value={values.CNPJ}
              onChange={({ target }) => {
                const notMasked = target.value.replace(/\D/g, "");

                if (notMasked.length <= 14) {
                  const masked = formatCNPJOrCPF(notMasked);
                  setFieldValue("CNPJ", masked);
                }
              }}
            />
          </Grid>
          <Grid className={classes.lastName} item xs={12} md={6} lg={4}>
            <Field
              name="companyName"
              as={TextField}
              variant="outlined"
              placeholder="Razão Social"
              fullWidth
            />
          </Grid>

          <IconButton onClick={handleAddCNPJ}>
            <Tooltip title="Adicionar CNPJ">
              <AddCircleOutlined style={{ color: "#1D8348" }} />
            </Tooltip>
          </IconButton>
        </Grid>

        {values.attributes.CNPJ.length > 0 && (
          <Grid container style={{ marginTop: "4rem" }}>
            <Grid item xs={12} md={6} lg={8} className={classes.tableCompany}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">CNPJ ou CPF</TableCell>
                    <TableCell align="center">Razão Social</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {values.attributes.CNPJ.map((dataCNPJ, idx) => {
                    return (
                      <TableRow key={dataCNPJ.CNPJ}>
                        <TableCell align="center">
                          {formatCNPJOrCPF(dataCNPJ.CNPJ)}
                        </TableCell>
                        <TableCell align="center">
                          {dataCNPJ.companyName}
                        </TableCell>
                        <TableCell>
                          <Grid container justify="center">
                            <IconButton
                              onClick={() => {
                                const newCNPJ = [...values.attributes.CNPJ];
                                newCNPJ.splice(idx, 1);
                                setFieldValue("attributes.CNPJ", newCNPJ);
                              }}
                            >
                              <Tooltip title="Remover CNPJ">
                                <DeleteForeverOutlined />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
              <Typography align="center" color="error">
                {errors.attributes?.CNPJ}
              </Typography>
            </Grid>
          </Grid>
        )}

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          style={{ marginTop: "5rem" }}
        >
          <Grid item>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              endIcon={
                loading && (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "2rem", height: "2rem" }}
                  />
                )
              }
            >
              {loading ? "Enviando ..." : "Enviar"}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default FormEditUser;
